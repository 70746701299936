import React from "react"

import Layout from "../components/layout";

import "aos/dist/aos.css";

import NewsOver from "@components/news-over/news-over";
import NewsOverviewGrid from "@components/news-overview-grid/news-overview-grid";

const NewsOverview = () => (
  <div className="news-overview">
    <Layout>
      <NewsOver />
      <NewsOverviewGrid />
    </Layout>
  </div>
)

export default NewsOverview
